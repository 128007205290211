/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #333333;
  font-family: MainFont, 'Trebuchet MS', Helvetica, sans-serif;
} */

.App {
  width: 100%;
  /* margin: 0 auto 160px; */
  display: flex;
  flex-direction: column;
  padding: 20px;

}

.content {
display: flex;
flex-direction: row;
}

h1 {
  color: rgb(0, 119, 255);
  font-size: 36px;
  font-weight: 400;
  /* border-bottom: 1px solid #333333; */
  padding: 24px 0 12px;
  text-align: center;
}

h2 {
  margin: 0px 24px;
}

.select {
  margin: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  list-style-type: none;
  padding: unset;
}

.select li {
  background-color: rgba(222, 211, 230, 0.3);
  /* background-color: rgba(222, 211, 230, 0.3); */
  height: 172px;
  width: auto;
  padding: 18px 24px 24px;
}

.select li:hover {
  cursor: pointer;
  background-color: rgba(222, 211, 230, 0.6);
}

.select li p {
  margin-bottom: 8px;
}

.select .thumb {
  height: 100px;
  overflow: hidden;
  display: flex;
}

.select .thumb img {
  object-fit: cover;
  width: 100%;
}

.quiz .item {
  margin: 24px 24px 24px 72px;
}

.quiz .question {
  margin-bottom: 6px;
}

.quiz .answers {
  list-style-type: none;
}

.quiz .answers li {
  margin: 6px 0 0 24px;
  padding: 3px 6px 3px;
  cursor: default;
  width: max-content;
}

.quiz .answers input {
  margin-right: 6px;
}

.quiz .answer {
  pointer-events: none;
}

.submit {
  width: 300px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
}

.submit button {
  padding: 12px 32px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
}

.submit button:hover {
  cursor: pointer;
}

.correct {
  background-color: rgb(184, 255, 184);
}

.yes-point {
  background-color: rgb(76, 241, 76);
}

.yes-point::before {
  content: '';
  background-image: url('../src/img/check.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-color: unset;
  position: absolute;
  margin-left: -40px;
  margin-top: -10px;
}

.no-point {
  background-color: rgb(255, 177, 177);
}

.no-point::before {
  content: '';
  background-image: url('../src/img/cross.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  display: inline-block;
  background-color: unset;
  position: absolute;
  margin-left: -36px;
  margin-top: -2px;
}

.disable-radio {
  pointer-events: none;
}
